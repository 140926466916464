import { render, staticRenderFns } from "./add-info.vue?vue&type=template&id=4c8bf739&scoped=true&"
import script from "./add-info.vue?vue&type=script&lang=js&"
export * from "./add-info.vue?vue&type=script&lang=js&"
import style0 from "./add-info.vue?vue&type=style&index=0&id=4c8bf739&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8bf739",
  null
  
)

export default component.exports